// 必要なnode_module読み込み
const { gsap } = require("gsap");
const { ScrollTrigger } = require("gsap/ScrollTrigger");

// コンポーネントのエクスポート
module.exports = function () {
	const element = '[data-type="animation"]';
	const trigger = '[data-type="animation-trigger"]';

	gsap.registerPlugin(ScrollTrigger);

	gsap.defaults({
		ease: "power1",
	});
	gsap.set(trigger, {
		autoAlpha: 0,
		y: 100,
	});

	ScrollTrigger.batch(trigger, {
		onEnter: (batch) =>
			gsap.to(batch, {
				autoAlpha: 1,
				duration: 2,
				y: 0,
			}),
		start: "top 50%",
		once: true,
	});
};
