// CSS
import "../assets/sass/app.scss";

// JS
// import { hello } from "../assets/js/components/sub";
import hamburgerAnimation from "../assets/js/components/hamburger_animation";
import smoothScroll from "../assets/js/components/smooth_scroll";
import gsap from "../assets/js/components/gsap";

// importのjsに定義されたJavaScriptを実行
// hello();
hamburgerAnimation();
smoothScroll();
gsap();

// ページ読み込み完了時に実行
window.addEventListener("DOMContentLoaded", function () {});
