// Hamburger Animation
const $ = require("jquery");
const element = "[data-type='navi-button']";
const navi = "[data-type='navi']";
const naviList = "[data-type='navi-list']";
const breakpoint = 840;

// コンポーネントのエクスポート
module.exports = function () {
	function MenuAction() {
		var flag = false;

		function naviOpen() {
			$(element).addClass("open");
			$(element).removeClass("close");
			$("html, body").css("overflow", "hidden");
			flag = true;
		}

		function naviClose() {
			$(element).removeClass("open");
			$(element).addClass("close");
			$("html, body").css("overflow", "");
			flag = false;
		}

		function scrollAction() {
			var navi_height = $(navi + ".open").height();
			var navi_list_height = $(".open " + naviList).height();
			if (navi_height < navi_list_height) {
				if (flag == false) {
					noScroll();
				} else {
					returnScroll();
				}
			}
		}

		$(window).on("resize", function () {
			var window_width = $(window).width();
			if (breakpoint < window_width) {
				$(navi).removeClass("open");
				$(element).removeClass("open");
				$("html, body").css("overflow", "");
				flag = false;
			}
		});

		$(element).on("click", function () {
			$(navi).toggleClass("open");

			setTimeout(function () {
				if ($(navi).hasClass("open")) {
					scrollAction();
				}
			}, 500);

			if (flag == false) {
				return naviOpen();
			} else {
				return naviClose();
			}
		});

		// Link id
		$(navi + " a").on("click", function () {
			$(navi).removeClass("open");
			$(element).addClass("close");
			$("html, body").css("overflow", "");
			flag = false;
			scrollAction();
		});
	}

	//ナビオープン時のスクロール停止
	function scrollControl(event) {
		event.preventDefault();
	}

	function noScroll() {
		document.addEventListener("mousewheel", scrollControl, { passive: false });
		document.addEventListener("touchmove", scrollControl, { passive: false });
	}

	function returnScroll() {
		document.removeEventListener("mousewheel", scrollControl, {
			passive: false,
		});
		document.removeEventListener("touchmove", scrollControl, {
			passive: false,
		});
	}

	var menu = new MenuAction();
};
